<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('CLIENTS.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span v-if="totalCount" class="text-dark-50 font-weight-bold mr-5" id="kt_subheader_total">
          {{ totalCount }} {{ $t('BASE.TOTAL') }}
        </span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              v-model="search"
              id="kt_subheader_search_form"
              trim
              type="text"
              debounce="500"
              :placeholder="$t('BASE.SEARCH')"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_clients']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyClientsCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('CLIENTS.NEW') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <Table v-cloak :fields="fields" :items="items" :busy.sync="loading">
      <template #cell(name)="data">
        <div style="min-width: 150px">
          <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            <span v-if="data.item.firstName">{{ data.item.firstName }}</span>
            <span v-if="data.item.lastName"> {{ data.item.lastName }}</span>
          </div>
        </div>
      </template>
      <template #cell(credentials)="data">
        <div style="min-width: 150px">
          <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.phone }}</div>
        </div>
      </template>
      <template #cell(created)="data">
        <div style="min-width: 150px">
          <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            <span class="font-weight-bolder font-size-sm">
              <span class="text-dark-75 mr-3">{{ $moment(data.item.createdAt).format(FORMAT_DATE) }}</span>
              <span class="text-muted">{{ $moment(data.item.createdAt).format(FORMAT_TIME) }}</span>
            </span>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 160px">
          <show-qr-code-button classes="" :has-active-bot="hasActiveBot" :client-uuid="data.item.uuid" />
          <router-link
            v-permission="['edit_orders']"
            :to="{ name: 'companyOrdersCreate', query: { client: data.item.uuid }}"
            class="btn btn-icon btn-light-success btn-sm ml-3"
          >
            <span class="svg-icon btn-light-success">
              <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
            </span>
          </router-link>
          <router-link
            v-permission="['edit_clients']"
            :to="{ name: 'companyClientsEdit', params: { id: data.item.uuid }}"
            class="btn btn-icon btn-light-info btn-sm ml-3"
          >
            <span class="svg-icon btn-light-primary">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </router-link>
          <div v-permission="['edit_clients']" class="btn btn-icon btn-light-danger btn-sm ml-3"
               @click="onDelete(data.item)"
          >
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </div>
      </template>
    </Table>
    <div v-if="totalCount && params.pagination.limit < totalCount" class="mt-3">
      <Paginations v-model="params.pagination.page"
                   :total-rows="totalCount"
                   :per-page="params.pagination.limit"
                   align="right"
      />
    </div>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/clientsRepository'
import SettingsRepo from '@/core/repository/company/settingsRepository'
import { FORMAT_DATE, FORMAT_TIME } from '@/helpers/validation'
import ShowQrCodeButton from '@/components/bot/ShowQrCodeButton'
import KTCard from '../../../content/Card'
import Paginations from '../../../../components/UI/Paginations'
import Table from '../../../../components/UI/Table'
import InputForm from '../../../../components/forms-items/input'
import baseList from '../../../../mixins/baseList'

export default {
  name: 'CompanyClientsIndex',
  components: {
    InputForm,
    Table,
    Paginations,
    KTCard,
    ShowQrCodeButton,
  },
  mixins: [baseList],
  data() {
    return {
      hasActiveBot: false,
      FORMAT_DATE,
      FORMAT_TIME,
      fields: [
        {
          key: 'name',
          label: this.$t('CLIENTS.NAME'),
          sortable: false,
        },
        {
          key: 'credentials',
          label: this.$t('CLIENTS.CREDENTIALS'),
          sortable: false,
        },
        {
          key: 'created',
          label: this.$t('CLIENTS.CREATED'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    Repo() {
      return Repo
    },
    imgUrl() {
      return (href) => `${process.env.VUE_APP_BACKEND_ORIGIN}/${href}`
    },
  },
  watch: {
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  mounted() {
    SettingsRepo.get()
      .then((settings) => {
        if (settings.activeTelegramBot) {
          this.hasActiveBot = true
        }
      })
      .catch(() => {})
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.CLIENTS') }])
  },
}
</script>

<style scoped>

</style>
