import Entity from './entity'

export default class Settings extends Entity {
    /** @type String */
    uuid
    /** @type String */
    mailerFromEmail
    /** @type *[] */
    currencyProviders = []
    /** @type Number */
    prolongateServicePricesDaysBefore
    /** @type Number */
    prolongateServicePricesDaysCount
    /** @type Bot */
    activeTelegramBot

    persistentProps() {
      return {
        mailerFromEmail: this.mailerFromEmail,
        currencyProviders: this.currencyProviders,
        prolongateServicePricesDaysBefore: this.prolongateServicePricesDaysBefore,
        prolongateServicePricesDaysCount: this.prolongateServicePricesDaysCount,
        activeTelegramBot: this.activeTelegramBot ? this.activeTelegramBot.uuid : null,
      }
    }
}
