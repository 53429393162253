import EntityRepository from '@/core/repository/entityRepository'
import { typeOf } from '@/helpers/type'
import Settings from '@/model/settings'
import botRepository from '@/core/repository/company/botRepository'
import Entity from '@/model/entity'

export default new class SettingsRepository extends EntityRepository {
  baseUri() {
    return 'company/settings'
  }

  mapEntity(props, prev = null) {
    if (props == null) {
      return null
    }
    let ent = prev
    if (!typeOf(ent, Settings)) {
      ent = new Settings()
    }
    ent.uuid = props.uuid || ent.uuid
    ent.mailerFromEmail = props.mailerFromEmail || ent.mailerFromEmail
    ent.currencyProviders = props.currencyProviders || ent.currencyProviders
    ent.prolongateServicePricesDaysBefore = props.prolongateServicePricesDaysBefore || ent.prolongateServicePricesDaysBefore
    ent.prolongateServicePricesDaysCount = props.prolongateServicePricesDaysCount || ent.prolongateServicePricesDaysCount
    ent.activeTelegramBot = botRepository.mapEntity(props.activeTelegramBot) || ent.activeTelegramBot

    return ent
  }
  
  get = async () => {
    return this.list().then(({ data }) => this.mapEntity(data.payload))
  };
  /**
   * @param entity {Entity}
   * @returns {Promise<Object>}
   */
  save = async (entity) => {
    if (!typeOf(entity, Settings)) {
      throw new TypeError(`Agrument must be of type <${Entity.name}>`)
    }
    return this.post(entity.persistentProps()).then(({ data }) => {
      this.mapEntity(data.payload, entity)
    })
  };
}()
